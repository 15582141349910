html {
  height: 100%;
  box-sizing: border-box;
  font-family: "Open Sans" !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  /* padding-bottom: 10rem; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  font-size: 1.25rem !important;
}

.bgcl {
  background-color: lightgrey;
}

.card-body ul {
  margin-block-end: 0px;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: rgb(221, 221, 221) 0px 0px 10px 2px;
  border-bottom-color: rgb(204, 204, 204);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bluebox {
  padding-top: 0.5em !important;
  background-color: #f8f9fa !important;
  padding-bottom: 0.8em;
  border-bottom-color: rgb(222, 226, 230);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.5em;
}

.padded {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-weight: normal;
}

.pad {
  margin-top: 1.8em;
  margin-bottom: 0.4em;
}

.timeline {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0 auto;
}

.timeline td {
  padding: 0;
  font-size: 0.8em;
  width: 150;
  text-align: center;
  vertical-align: top;
}

.light {
  color: #b0b0b0;
}

.events {
  font-size: 0.8rem;
  font-weight: normal;
}

.bugscount {
  padding-left: 2em;
  font-size: 0.8rem;
}

.smallsuper {
  vertical-align: super;
  font-size: 0.5em;
}

.btn-wrap-text {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.btn-tag {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}

.btn-selected {
  color: #000;
  background-color: #aec4e3;
  border-color: #aec4e3;
}
ol.steps > li {
  padding-bottom: 0.4em;
}

.card {
  font-size: 0.9rem !important;
}

.cards {
  height: 100%;
}

.card-body {
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
  padding-left: 0.8em !important;
  padding-right: 0.8em !important;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  text-align: center;
  color: #777;
  font-size: 0.8em;
  background-color: #eee;
  box-shadow: inset rgb(211, 211, 211) 0px 6px 10px -4px;
  border-top-color: rgb(204, 204, 204);
  border-top-style: solid;
  border-top-width: 1px;
}

.card-columns {
  columns: 2;
}

.card-title {
  font-weight: bold;
  margin-bottom: 1.5em;
}

.event {
  margin-bottom: 1.5em;
}

.minutes {
  margin-left: 0.5em;
  margin-bottom: 0.5em;
  padding-left: 4px;
}

.date {
  width: 3.7em;
  margin-right: 1.5em;
}

.month {
  width: 100%;
  text-align: center;
  border: 1px solid #ccc;
  font-size: 0.8em;
  font-weight: bold;
  background-color: #ccc;
  border-radius: 8px 8px 0 0;
}

.day {
  font-size: 1.5em;
  text-align: center;
  width: 100%;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-radius: 0 0 8px 8px;
}

.title {
  font-size: 1.2em;
}

.clickable {
  cursor: pointer;
}

.card-selected {
  background-image: linear-gradient(to bottom, #eee 0%, rgb(241, 240, 240) 100%);
  border: #c8c9ca solid 2px;
}
.wrapper {
  display: flex;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.wizard {
  position: relative;
  padding: 40px 3px;
  width: 100%;
}
.actions {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-container {
  min-height: 5rem;
  margin-bottom: 10px;
  margin-top: 10px;
}
.disabled {
  pointer-events: none;
  cursor: default;
  color: #7d7c7c;
}

.enable {
  cursor: pointer;
  color: #3864a3;
  text-decoration: none;
  background-color: transparent;
}
.enable:hover {
  text-decoration: underline;
}

.overlay {
  background-color: #fff;
  color: #ddd;
  transition: background-color 200ms linear;
  transition: color 200ms linear;
}

.cardHeader {
  background: rgb(165, 164, 164);
}

.manual-wrapper {
  margin-top: 3em;
  height: 70vh;
}
.color {
  background-color: #84c48e;
  color: white;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: rgb(90, 90, 90);
  background-color: #6fb37a;
}

.sticky {
  position: sticky;
  top: 1em;
}

.scrollable-container {
  position: relative;
}

.bottom-padding {
  margin-bottom: 5px;
}
